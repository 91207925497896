import { DOT, MODDED_KEY_VALUE_FILE } from "./constants";
/** Maps key-value string descriptions to their civs */
const CIV_KEY_MAP = {
    "120150": "britons",
    "120151": "franks",
    "120152": "goth",
    "120153": "teutons",
    "120154": "japanese",
    "120155": "chinese",
    "120156": "byzantines",
    "120157": "persians",
    "120158": "saracens",
    "120159": "turks",
    "120160": "vikings",
    "120161": "mongols",
    "120162": "celts",
    "120163": "spanish",
    "120164": "aztecs",
    "120165": "mayans",
    "120166": "huns",
    "120167": "koreans",
    "120168": "italians",
    "120169": "indians",
    "120170": "incas",
    "120171": "magyars",
    "120172": "slavs",
    "120173": "portuguese",
    "120174": "ethiopians",
    "120175": "malians",
    "120176": "berbers",
    "120177": "khmer",
    "120178": "malay",
    "120179": "burmese",
    "120180": "vietnamese",
    "120181": "bulgarians",
    "120182": "tatars",
    "120183": "cumans",
    "120184": "lithuanians",
    "120185": "burgundians",
    "120186": "sicilians",
    "120187": "poles",
    "120188": "bohemians",
};
/** Map of civ names to info */
const CIV_MAP = new Map();
export const getOrigCiv = (name) => {
    const civ = CIV_MAP.get(name);
    if (civ)
        return civ;
    else
        throw Error(`Civ "${name}" does not exist`);
};
export const getNameByCode = (code) => {
    const name = CIV_KEY_MAP[code];
    if (name)
        return name;
    else
        throw Error(`Civ with code "${code}" does not exist`);
};
/** Array of each civ's name in alphabetical order */
export const CIV_NAMES = Object.values(CIV_KEY_MAP)
    .sort((a, b) => a.localeCompare(b))
    .map((s) => s);
/** Retrives and builds civ models and fills {@link CIV_MAP} */
export const buildCivs = async () => {
    console.log("fetching civ info");
    const r = await fetch(`aoe/${MODDED_KEY_VALUE_FILE}`);
    const txt = await r.text();
    console.log("ingesting civ info");
    // Separate descriptions into array
    const lines = txt.split(/\n/);
    // Split lines into key-description pairs
    const pairs = lines.map((L) => L.split(/(?<=^\d{3,7})\s+(?=")/));
    // Build civs
    pairs.forEach(([key, description]) => {
        console.log(`\t${CIV_KEY_MAP[key]} (${key})`);
        // clean stylings
        const cleaned = description
            .replace(/"/gi, "")
            .replace(/\\n/g, "\n")
            .replace(new RegExp(DOT, "g"), "-")
            .trim();
        CIV_MAP.set(CIV_KEY_MAP[key], {
            key,
            description: cleaned,
        });
    });
    console.log("civs built");
};
