<script lang="ts">
  export let page: "INFO" | "EDITOR"
</script>

<nav>
  <ul>
    <li>
      <button>
        <svg
          viewBox="0 0 128 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_14_11)">
            <g clip-path="url(#clip1_14_11)">
              <path
                d="M89.075 10.3489C87.8196 10.7038 86.67 11.3603 85.7264 12.2612C84.7828 13.1621 84.0738 14.28 83.6613 15.5177L72 50.5002L73.8362 52.3364L92.6 33.5727C92.2262 32.7902 92 31.9252 92 31.0002C92 27.6864 94.6863 25.0002 98 25.0002C101.314 25.0002 104 27.6864 104 31.0002C104 34.3139 101.314 37.0002 98 37.0002C97.075 37.0002 96.21 36.7739 95.4275 36.4002L76.6638 55.1639L78.5 57.0002L113.482 45.3389C114.72 44.9264 115.838 44.2174 116.739 43.2738C117.64 42.3302 118.296 41.1806 118.651 39.9252L124 21.0002L108 5.00019L89.075 10.3489ZM134.243 2.27144L126.729 -5.24231C124.385 -7.58606 120.584 -7.58606 118.24 -5.24231L111.171 1.82644L127.174 17.8289L134.243 10.7602C136.586 8.41644 136.586 4.61644 134.243 2.27144Z"
                fill="white"
              />
            </g>
            <path
              d="M63.3413 80.8335C62.9957 79.4402 61.2522 78.9681 60.2369 79.9834L48.7634 91.4568L38.2898 89.7118L36.5447 79.2381L48.0181 67.7646C49.0396 66.7432 48.5474 65.0027 47.1448 64.654C39.8343 62.8425 31.7846 64.7944 26.071 70.5065C19.9547 76.6228 18.2713 85.4887 20.8172 93.2189L-12.1081 126.144C-15.964 130 -15.964 136.252 -12.1081 140.108C-8.25223 143.964 -2.00014 143.964 1.85573 140.108L34.7533 107.211C42.4866 109.789 51.3355 108.087 57.492 101.93C63.2117 96.2107 65.1605 88.1487 63.3413 80.8335ZM-5.12464 136.828C-7.16907 136.828 -8.82776 135.169 -8.82776 133.125C-8.82776 131.079 -7.16907 129.422 -5.12464 129.422C-3.08021 129.422 -1.42153 131.079 -1.42153 133.125C-1.42153 135.169 -3.08021 136.828 -5.12464 136.828Z"
              fill="white"
            />
            <path
              d="M34.3359 46L31.5742 37.5508H16.6719L13.7188 46H7.59375L23.8633 5.39453H25.3125L40.4062 46H34.3359ZM24.3281 15.8945L18.1211 33.5312H30.0156L24.3281 15.8945Z"
              fill="white"
            />
            <path
              d="M49.2383 62.2891C49.2383 57.7682 50.4505 54.1224 52.875 51.3516C55.3177 48.5625 58.526 47.168 62.5 47.168C66.6927 47.168 69.9466 48.5078 72.2617 51.1875C74.5768 53.849 75.7344 57.5495 75.7344 62.2891C75.7344 67.0104 74.5495 70.7383 72.1797 73.4727C69.8099 76.1888 66.5833 77.5469 62.5 77.5469C58.3255 77.5469 55.0716 76.1706 52.7383 73.418C50.4049 70.6654 49.2383 66.9557 49.2383 62.2891ZM54.707 62.2891C54.707 69.6354 57.3047 73.3086 62.5 73.3086C64.9245 73.3086 66.8203 72.3242 68.1875 70.3555C69.5729 68.3867 70.2656 65.6979 70.2656 62.2891C70.2656 55.0339 67.6771 51.4062 62.5 51.4062C60.1302 51.4062 58.2344 52.3724 56.8125 54.3047C55.4089 56.237 54.707 58.8984 54.707 62.2891Z"
              fill="white"
            />
            <path
              d="M94.5586 85.8633V96.9922H107.793V101.641H94.5586V116.078H112.715V121H89.0898V80.9414H113.016V85.8633H94.5586Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_14_11">
              <rect width="128" height="128" fill="white" />
            </clipPath>
            <clipPath id="clip1_14_11">
              <rect
                width="64"
                height="64"
                fill="white"
                transform="translate(72 -7)"
              />
            </clipPath>
          </defs>
        </svg>
        <span>Age of Revisionism</span>
      </button>
    </li>
    <li>
      <button on:click={() => (page = "EDITOR")}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="pen-to-square"
          class="svg-inline--fa fa-pen-to-square"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          ><path
            fill="currentColor"
            d="M495.6 49.23l-32.82-32.82C451.8 5.471 437.5 0 423.1 0c-14.33 0-28.66 5.469-39.6 16.41L167.5 232.5C159.1 240 154.8 249.5 152.4 259.8L128.3 367.2C126.5 376.1 133.4 384 141.1 384c.916 0 1.852-.0918 2.797-.2813c0 0 74.03-15.71 107.4-23.56c10.1-2.377 19.13-7.459 26.46-14.79l217-217C517.5 106.5 517.4 71.1 495.6 49.23zM461.7 94.4L244.7 311.4C243.6 312.5 242.5 313.1 241.2 313.4c-13.7 3.227-34.65 7.857-54.3 12.14l12.41-55.2C199.6 268.9 200.3 267.5 201.4 266.5l216.1-216.1C419.4 48.41 421.6 48 423.1 48s3.715 .4062 5.65 2.342l32.82 32.83C464.8 86.34 464.8 91.27 461.7 94.4zM424 288c-13.25 0-24 10.75-24 24v128c0 13.23-10.78 24-24 24h-304c-13.22 0-24-10.77-24-24v-304c0-13.23 10.78-24 24-24h144c13.25 0 24-10.75 24-24S229.3 64 216 64L71.1 63.99C32.31 63.99 0 96.29 0 135.1v304C0 479.7 32.31 512 71.1 512h303.1c39.69 0 71.1-32.3 71.1-72L448 312C448 298.8 437.3 288 424 288z"
          /></svg
        >
        <span>Editor</span>
      </button>
    </li>
    <li>
      <button on:click={() => (page = "INFO")}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="circle-question"
          class="svg-inline--fa fa-circle-question"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          ><path
            fill="currentColor"
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"
          /></svg
        >
        <span>Info</span>
      </button>
    </li>
    <li>
      <button
        on:click={() => window.open("https://jonoaugustine.com", "_blank")}
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="diamond"
          class="svg-inline--fa fa-diamond"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          ><path
            fill="currentColor"
            d="M497.1 222.1l-208.1-208.1c-18.71-18.71-49.04-18.71-67.74 0L14.03 222.1c-18.71 18.71-18.71 49.04 0 67.74l208.1 208.1c18.71 18.71 49.04 18.71 67.74 0l208.1-208.1C516.7 271.2 516.7 240.8 497.1 222.1z"
          /></svg
        >
        <span>Made by Jono</span>
      </button>
    </li>
  </ul>
</nav>

<style>
  nav {
    width: 5rem;
    height: 100vh;
    background-color: var(--background-secondary);
    transition: width 200ms ease;
    position: fixed;
  }

  nav:hover {
    width: 14rem;
  }

  ul {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
  }

  li,
  button {
    width: 100%;
  }

  li:last-child {
    margin-top: auto;
  }

  li:last-child button {
    color: var(--status-good);
  }

  li:last-child button:hover {
    color: var(--text-dark);
  }

  button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    color: var(--text-primary);
    background-color: transparent;
    transition: background-color 200ms ease;
  }

  button:hover {
    cursor: pointer;
    background-color: var(--status-neutral);
    color: var(--text-dark);
  }

  nav:hover button {
    justify-content: unset;
    padding-left: 1rem;
  }

  button svg {
    width: 2rem;
  }

  button span {
    display: none;
    margin-left: 1rem;
  }

  nav:hover button span {
    display: block;
    margin-left: 1rem;
  }

  @media screen and (min-width: 1200px) {
    nav {
      width: 15rem;
    }

    nav:hover {
      width: 15rem;
    }

    button {
      justify-content: unset;
      padding-left: 2rem;
    }

    nav:hover button {
      padding-left: 2rem;
    }

    button span {
      display: block;
    }
  }
</style>
