import JSZip from "jszip";
import FileSaver from "file-saver";
import { getNameByCode } from "./Civ";
import { CIV_TECH_TREE, DOT, MODDED_KEY_VALUE_FILE, MOD_RESOURCE_PATH, } from "./constants";
/**
 * @returns {string} The formatted civ description
 */
const buildModString = (civ) => {
    return `${civ.key} "${civ.description
        .replace(/\n/g, "\\n")
        .replace(/-/g, DOT)}"`;
};
/**
 * Creates mod files and attempts to save them to the device.
 *
 * ```
 * /custom-civ-descriptions-<datetime>
 *  - info.json
 *  - /widgetui
 *    - civTechTrees.json
 *  - /resources/en/strings/key-value
 *    - key-value-modded-strings-utf8.txt
 * ```
 *
 * @param civs List of modified civs
 * @param locale language locality
 * @param name name of the zip file
 * @returns the file saving promise
 */
export const exportMod = async (civs, locale, name) => {
    console.log(`Exporting mod | ${locale} ${name}`);
    // Get the techtree json which is needed for some reason
    const civTechTreeJob = fetch(`aoe/${CIV_TECH_TREE}`);
    // Create a new JSZip instance to work with
    const zip = new JSZip();
    // Make folder instance for the mod root folder
    const home = zip.folder(name);
    // Create info.json file
    const civNames = civs.map((c) => getNameByCode(c.key)).join(", ");
    home.file("info.json", JSON.stringify({
        Author: "Dr.H",
        CacheStatus: 1,
        Description: `Changes descriptions of ${civNames} in the tech tree`,
        Title: name,
    }));
    // Create actual mod file at the given locality path
    home.folder(MOD_RESOURCE_PATH(locale)).file(MODDED_KEY_VALUE_FILE, civs.map(buildModString).reduce((p, c) => `${p}\n${c}`));
    // Create file for civTechTree file which the mod MUST have to work
    const civTechTree = await (await civTechTreeJob).text();
    home.folder("widgetui").file(CIV_TECH_TREE, civTechTree);
    // Generate blob and save file
    const blob = await home.generateAsync({ type: "blob" });
    return await FileSaver.saveAs(blob, `${name}.zip`); // returns void
};
