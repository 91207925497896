<script lang="ts">
  import { buildCivs } from "./Civ"
  import { currentPage } from "./store"
  import Sidebar from "./components/Sidebar.svelte"
  import Editor from "./pages/Editor.svelte"
  import Info from "./pages/Info.svelte"

  // Start building civs
  const builderJob = buildCivs()
</script>

<Sidebar bind:page={$currentPage} />

<main>
  {#if $currentPage === "EDITOR"}
    <Editor {builderJob} />
  {:else}
    <Info />
  {/if}
</main>

<style>
  main {
    margin-left: 5rem;
    padding: 1% 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  @media screen and (min-width: 1200px) {
    main {
      margin-left: 15rem;
    }
  }
</style>
