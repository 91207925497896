<script lang="ts">
  import type { Civ, CivName } from "../Civ"
  export let save: (a: CivName, b: Civ) => void
  export let reset: (a: CivName) => void
  export let selectedName: CivName
  export let civ: Civ

  let area

  const submit = () => {
    save(selectedName, { ...civ, description: area.value })
  }

  const resetCiv = () => {
    area.value = civ.description
    reset(selectedName)
  }
</script>

<textarea bind:this={area} rows="20" value={civ.description} />

<div class="form-buttons">
  <button on:click={submit}>Save</button>
  <button color="red" on:click={resetCiv}>Reset Civ</button>
</div>

<style>
  textarea {
    width: 100%;
    background-color: transparent;
    color: unset;
    outline: none;
    border: none;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .form-buttons {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    .form-buttons {
      /*flex-direction: row;*/
    }
  }
</style>
