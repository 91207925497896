/** The source file modded key-value string files are overriding */
export const BASE_KEY_VALUE_FILE = "key-value-strings-utf8.txt";
/** The override key-value string file */
export const MODDED_KEY_VALUE_FILE = "key-value-modded-strings-utf8.txt";
/** File name of civTechTrees.json */
export const CIV_TECH_TREE = "civTechTrees.json";
/**
 * @param {Locale} locale - language locality
 */
export const MOD_RESOURCE_PATH = (locale) => `resources/${locale}/strings/key-value`;
export const MOD_WIDGETUI_PATH = `widgetui/${CIV_TECH_TREE}`;
/** The dot AoE uses for bullet points */
export const DOT = "•";
export const LOCALES = [
    "br",
    "en",
    "de",
    "es",
    "fr",
    "hi",
    "it",
    "jp",
    "ko",
    "ms",
    "mx",
    "pl",
    "ru",
    "tr",
    "tw",
    "vi",
    "zh",
];
