<script lang="ts">
  import { CivName, CIV_NAMES } from "../Civ"

  export let selection: CivName
</script>

<div>
  <label for="civ-select">Choose a Civ to Modify</label>
  <select bind:value={selection} name="civ-select" id="civ-select">
    <option value="" />
    {#each CIV_NAMES as name}
      <option value={name}>{name[0].toUpperCase()}{name.substring(1)}</option>
    {/each}
  </select>
</div>

<style>
  div {
    display: flex;
    justify-content: center;
  }
</style>
