<script lang="ts">
  import { currentPage } from "../store"

  const openEditorPage = () => currentPage.set("EDITOR")
</script>

<section id="info">
  <article id="general">
    <h1>Age Of Revisionism</h1>
    <hr />
    <!--TODO Info and stuff-->
  </article>
  <article id="guide">
    <h2>Guide</h2>
    <ul>
      <!--Pick-->
      <li>
        <p>
          Choose a civilization to modify from the dropdown menu in the
          <a href="#editor" on:click={openEditorPage}>Editor page</a>.
        </p>
        <img src="images/gif_guide_pick_civ.gif" alt="Pick Civilization" />
      </li>
      <!--Edit-->
      <li>
        <p>
          Make whatever changes to the description you want and click the Save
          button. Once you save, you are free to edit other civ description
          without losing progreess! <br />({"<b>X<b>"} will make X bold).
        </p>
        <img src="images/gif_guide_edit_civ.gif" alt="Edit Civilization" />
      </li>
      <!--Export-->
      <li>
        <p>
          Once your descriptions have been saved, choose your mod's details and
          export the zip file.
        </p>
        <img
          style="max-width: 80% !important;"
          src="images/gif_guide_export_changes.gif"
          alt="Export changes"
        />
      </li>
      <li>
        <p>
          Now that you have your mod downloaded, you can either install it
          locally or upload it to share with the AoE community!
        </p>
        <ul style="list-style-type:lower-greek">
          <li>
            <p>
              To use your mod locally, open your AoE2:DE local mods folder. This
              can be found pretty quickly by opening your game and using an
              already installed mod and clicking <i><b>Open Directory</b></i>.
            </p>
            <img src="images/img_guide_game_ui.png" alt="In-game Mod UI" />
          </li>
          <li>
            <p>
              To upload your mod, go to <a
                href="https://www.ageofempires.com/mods/create/"
                target="_blank"
                rel="noopener noreferrer"
                >https://www.ageofempires.com/mods/create/</a
              >
              and upload the <i>.zip</i> file you exported. And whatever other details
              you like and set visibility to Public.
            </p>
          </li>
        </ul>
      </li>
    </ul>
  </article>
</section>

<style>
  #guide img {
    width: 100%;
    border: 0.1rem solid var(--status-neutral);
  }

  @media screen and (min-width: 1200px) {
    #guide img {
      max-width: 40%;
    }
  }

  #guide ul {
    list-style-type: decimal;
  }
</style>
