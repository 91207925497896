<script lang="ts">
  import { Civ, CivName, getOrigCiv } from "../Civ"
  import { exportMod } from "../export"
  import { modifiedCivs } from "../store"
  import CivSelector from "../components/CivSelector.svelte"
  import CivForm from "../components/CivForm.svelte"
  import { Locale, LOCALES } from "../constants"
  export let builderJob: Promise<void>

  // Store
  let modifiedCivList: string[]
  modifiedCivs.subscribe((map) => (modifiedCivList = [...map.keys()]))

  /** Saves the given civ to the modified civ store */
  let save = (civName: CivName, civ: Civ) => {
    console.log(`Saving ${civName}`, civ)
    modifiedCivs.update((map) => new Map(map.set(civName, civ)))
  }

  /** Removes the given civ from the modified civs store */
  let reset = (civName: CivName) => {
    modifiedCivs.update((map) => {
      map.delete(civName)
      return new Map(map)
    })
  }

  /** Clears all modified civs */
  let clearButtonTxt = "Clear All Changes"
  let clear = () => {
    if (clearButtonTxt === "Clear All Changes") {
      clearButtonTxt = "Click Again to clear changes"
    } else {
      clearButtonTxt = "Clear All Changes"
      modifiedCivs.set(new Map<string, Civ>())
    }
  }

  // Component State
  let selectedName: CivName
  $: civ =
    selectedName &&
    ($modifiedCivs.get(selectedName) || getOrigCiv(selectedName))

  let locale: Locale = "en"
  let name: string = `aor-civ-description-${Date.now()}`

  let exportJob: Promise<void>

  const complete = async () => {
    exportJob = exportMod(
      [...$modifiedCivs.values()],
      locale,
      name || undefined, // its not good but i...idc
    )
    await exportJob
    exportJob = undefined
  }
</script>

<section id="application">
  <section id="editor">
    {#await builderJob}
      <p>Loading Civ Data</p>
    {:then _}
      <CivSelector bind:selection={selectedName} />
      {#if civ}
        <h3>{selectedName}</h3>
        <CivForm {save} {reset} {selectedName} bind:civ />
      {/if}
    {:catch error}
      {console.log(error)}
      <p>Failed to load Civ Data. Try reloading the page.</p>
    {/await}
  </section>
  <section id="exporter">
    {#if exportJob !== undefined}
      {#await exportJob}
        <p>Exporting mod...</p>
      {:then _}
        <p>Export Complete!</p>
      {:catch error}
        {console.log(error)}
        <p>An error has occured. Your browser version may be incompatable</p>
      {/await}
    {:else}
      <h4 style="margin-bottom: 1%">Changes:</h4>
      <p>
        {#if modifiedCivList.length > 0}
          <!-- TODO Make each civ delete on click -->
          {modifiedCivList.reduce((p, c) => `${p}, ${c}`)}
        {/if}
      </p>
      {#if modifiedCivList.length > 0}
        <form on:submit={complete} id="export-settings">
          <div class="export-setting-group">
            <label for="export-name">Mod Name</label>
            <input
              type="text"
              name="export-name"
              id="export-name"
              bind:value={name}
            />
          </div>
          <div class="export-setting-group">
            <label for="export-locale">Mod Language</label>
            <select name="export-locale" id="export-locale" bind:value={locale}>
              {#each LOCALES as locale}
                <option value={locale}>{locale}</option>
              {/each}
            </select>
          </div>
          <div id="result-options">
            <button color="red" type="reset" on:click={clear}
              >{clearButtonTxt}</button
            >
            <button color="green" type="submit">Export Mod</button>
          </div>
        </form>
      {/if}
    {/if}
  </section>
</section>

<style>
  section#application {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  #exporter {
    display: flex;
    flex-direction: column;
  }

  #export-settings {
    display: flex;
    flex-direction: column;
  }

  #export-settings > * {
    margin-bottom: 1rem;
  }

  .export-setting-group:first {
    display: flex;
    flex-direction: column;
  }

  .export-setting-group label {
    margin-right: 1rem;
  }

  .export-setting-group input[type="text"] {
    width: 80%;
  }

  div#result-options {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1200px) {
    section#application {
      font-size: large;
      display: grid;
      grid-template-columns: 3fr 1fr;
      padding: 1% 0;
    }

    section#editor {
      max-width: 70%;
    }

    div#result-options {
      display: flex;
      flex-direction: row;
    }
  }
</style>
